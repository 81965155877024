/* Arrow.css */
.arrow-down {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 30px;
    height: 30px;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
}

.arrow {
    color: #000;
    font-size: 2.5em;
}

.arrow-down::before,
.arrow-down::after {
    content: '';
    display: block;
    position: absolute;
    transform-origin: 50% 50%;
}

.arrow-down::before {
    width: 17px;
    height: 17px;
    top: 100%;
    left: 70%;
    border-style: solid;
    border-color: #000;
    border-width: 2px 2px 0 0;
    transform: rotate(135deg);
    margin: -20px 0 0 -15px;
}

.arrow-down::after {
    width: 0;
    height: 59px;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #000;
    border-width: 0 2px 0 0;
    transform: translate(0px, -42px);
}

@media (prefers-reduced-motion: no-preference) {
    .arrow-down {
        animation: bounce 2s infinite;
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}