/* Gallery.css */
.gallery-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 0 5em;
    overflow-x: hidden;
}

.gallery__title {
    text-align: center;
    margin: 20px 0;
}

.artist-list {
    flex: 0.5 1;
    padding: 20px;
    line-height: 2.3rem;
    margin-bottom: 20px;
    border: 1px solid #d7d7d7;
}

.artist-name {
    cursor: pointer;
}

.artist-name:hover {
    color: rgba(0, 0, 0, 0.5);
}

.artwork-grid {
    flex: 3;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 65px;
    padding: 20px;
}

.artwork-item {
    text-align: left;
}

.artwork-item img,
.artwork-item video {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 0.5em;
}

.buy-button {
    cursor: pointer;
    padding: 0.5em 1em;
    border: none;
    background-color: #000000;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
}

.default-text {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 2em;
}

@media (max-width: 768px) {
    .gallery-container {
        padding: 0 1em;
        text-align: center;
    }

    .mobile-artist-selector {
        width: 100%;
        text-align: center;
        margin-bottom: 1em;
    }

    .dropdown-button {
        padding: 0.5em 1em;
        background-color: #f8f8f8;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        position: relative;
        color: #000;

    }

    .dropdown-arrow {
        margin-left: 10px;
    }

    .dropdown-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        position: absolute;
        width: 100%;
        z-index: 1000;
        margin-top: 10px;
    }

    .dropdown-menu .artist-name {
        padding: 0.5em 0;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .dropdown-menu .artist-name:hover {
        background-color: #f0f0f0;
    }

    .artwork-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .artist-list {
        display: none;
    }
}

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.hidden {
    opacity: 0;
}

.gallery-grid img {
    transition: opacity 0.3s ease-in-out;
}