/* Base scrollbar styles */
::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

/* Remove duplicate html/body styles and consolidate them */
html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: #bdbdbd;
}

body::-webkit-scrollbar-track {
    background-color: transparent;
}

.minimal-home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #bdbdbd;
    padding: 1.5rem 2rem 0;
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    overflow: auto;
}

header {
    padding: 1rem 0 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}

h1 {
    position: absolute;
    top: 1rem;
    left: 2rem;
    font-size: 15vw;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), #bdbdbd);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 300;
    margin: 0;
    letter-spacing: -0.02em;
    pointer-events: none;
    user-select: none;
    z-index: -2;
}

.launch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background: linear-gradient(145deg, #2a2a2a, #494949);
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    border-radius: 3px;
    font-size: 0.7rem;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    line-height: 1.2;
    padding: 0.4rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8);
    border-color: rgba(100, 100, 100, 0.2);
}

.launch-button:hover {
    background: linear-gradient(145deg, #333333, #3a3a3a);
    color: white;
    border-color: rgba(100, 100, 100, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    transform: translateY(-1px);
}

main {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
}

main::-webkit-scrollbar {
    width: 6px;
}

main::-webkit-scrollbar-track {
    background: transparent;
}

main::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.content-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: start;
    max-width: 900px;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 2rem;
    position: relative;
    z-index: 1;
    padding-top: 16vh;
    overflow: visible;
}

.large-launch-section {
    position: fixed;
    right: 2rem;
    top: 1rem;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    pointer-events: all;
}

.large-launch-section::before {
    content: '';
    position: absolute;
    width: 600px;
    height: 600px;
    background-image:
        linear-gradient(rgba(0, 0, 0, 0.035) 1px, transparent 1px),
        linear-gradient(90deg, rgba(0, 0, 0, 0.035) 1px, transparent 1px);
    background-size: 50px 50px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 0.8;
    mask-image: radial-gradient(circle at center, black 60%, transparent 100%);
    -webkit-mask-image: radial-gradient(circle at center, black 60%, transparent 100%);
}

.large-launch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background:
        radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.1) 0.5px, transparent 0),
        linear-gradient(225deg, #666666, #1a1a1a);
    background-size: 4px 4px, 100% 100%;
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    border-radius: 5px;
    font-size: .65rem;
    transition: all 0.2s ease;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    line-height: 1.4;
    padding: 1rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
    border-color: rgba(100, 100, 100, 0.2);
    position: relative;
    z-index: 2;
    cursor: not-allowed;
    pointer-events: all;
}

.large-launch-button::after {
    content: 'Check back soon';
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 10;
}

.large-launch-button:hover::after {
    opacity: 1;
}

.large-launch-button::before {
    display: none;
}

.art-placeholder {
    position: relative;
    width: 320px;
    height: 320px;
    margin: 0;
    justify-self: end;
}

.art-layer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 320px;
    border-radius: 4px;
}

#canvas-container {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    pointer-events: none;
}

#canvas-container canvas {
    border-radius: 4px;
    opacity: 0.8;
    mix-blend-mode: color-burn;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.stone-art-layer {
    z-index: 1;
    background-position: center;
    background-size: cover;
}

.logo-overlay-layer {
    z-index: 3;
    background-position: center;
    background-size: cover;
}

.info-section {
    justify-self: start;
    padding-top: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blog-post {
    max-width: 42ch;
    margin-bottom: 2rem;
}

.blog-post-header {
    position: relative;
    padding-bottom: 1rem;
    z-index: 2;
}

.blog-post-content {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.blog-post-content::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
}

.blog-post-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

.blog-post-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.post-title {
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.05em;
    color: #333;
    margin-bottom: 0.75rem;
    font-weight: 500;
}

.blog-post h2 {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: #333;
}

.blog-post-image {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 2px;
    margin-bottom: 0.5rem;
}

.blog-post p {
    font-size: 0.8rem;
    line-height: 1.4;
    color: #666;
    margin: 0;
}

.blog-post .artist-quote {
    font-style: italic;
    margin: 0;
}

.collection-button {
    text-align: center;
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.7rem;
    transition: all 0.3s ease;
    border: none;
    margin-top: 1rem;
    font-weight: 500;
}

.collection-button:hover {
    background-color: #444;
    transform: translateY(-1px);
}

.social-links {
    position: fixed;
    bottom: 0;
    left: 2rem;
    z-index: 10;
    padding: 1rem 0;
}

.social-links::before {
    content: '';
    position: absolute;
    top: 0;
    left: -2rem;
    right: -2rem;
    bottom: 0;
    background: rgb(189 189 189);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: -1;

}

.social-links a {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 0.8rem;
    position: relative;
    z-index: 2;
}

.social-links a:hover {
    color: rgba(0, 0, 0, 0.9);
}

.copyright {
    position: fixed;
    bottom: 1rem;
    right: 2rem;
    font-size: 0.7rem;
    color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

@media (max-width: 768px) {

    html,
    body {
        min-height: 100vh;
    }

    .minimal-home {
        padding: 1rem 1rem 0;
        min-height: 100vh;
        overflow: auto;
    }

    main {
        overflow: visible;
        height: auto;
    }

    .blog-post {
        max-width: 100%;
        margin-bottom: 4rem;
    }


    h1 {
        font-size: 0.65rem;
        top: 0.5rem;
        left: 1rem;
        text-align: left;
        background: none;
        -webkit-background-clip: unset;
        background-clip: unset;
        color: #333333;
    }

    .content-area {
        grid-template-columns: 1fr;
        gap: 1rem;
        padding: 0.5rem;
        padding-top: 1rem;
    }

    .large-launch-section {
        position: fixed;
        top: 1rem;
        right: 1rem;
        overflow: hidden;
    }

    .large-launch-button {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 3px;
        font-size: 0.7rem;
        background: linear-gradient(145deg, #2a2a2a, #494949);
        padding: 0.4rem;
    }

    .large-launch-button:hover {
        background: linear-gradient(145deg, #333333, #3a3a3a);
    }

    .info-section {
        justify-self: start;
        height: auto;
        padding-top: 2rem;
        margin-bottom: 2rem;
        order: 2;
        width: 100%;
    }

    .blog-post {
        max-width: 100%;
        margin-bottom: 4rem;
    }

    .large-launch-button::before {
        display: none;
    }

    .social-links {
        left: 1.5rem;
        height: 2rem;
    }

    .copyright {
        right: 1rem;
        bottom: 0.8rem;
        font-size: 0.65rem;
    }

    .large-launch-section {
        padding: 2rem;
        
        margin-bottom: 1rem;
    }

    .large-launch-button::before {
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: -2rem;
        border-radius: 40px;
    }
}

/* Add these loading screen styles */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Jacquarda Bastarda 9 Charted", serif;
    color: #282828;
}

.loading-content {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-title {
    position: absolute;
    top: 10%;
    z-index: 100;
    font-size: 1.2rem;
}

.loading-canvas-container {
    border: none;
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0px 3.9px 6.7px -0.5px rgba(0, 0, 0, 0.2);
    background-image: url('../public/static/media/stone-art.png');
    background-position: center;
    background-size: cover;
    z-index: 1;
}

.loading-overlay {
    border: none;
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 0px 3.9px 6.7px -0.5px rgba(0, 0, 0, 0.2);
    background-image: url('../public/static/media/i-trans.gif');
    background-position: center;
    background-size: cover;
    z-index: 10;
}

/* Desktop styles */
html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: #bdbdbd;

}

/* Mobile landscape styles */
@media (max-width: 768px) and (orientation: landscape) {

    html,
    body {
        overflow: auto;
        height: auto;
        min-height: 100vh;
    }

    .minimal-home {
        height: auto;
        min-height: 100vh;
        overflow: auto;
    }

    .blog-post {
        height: auto;
        min-height: calc(100vh - 12rem);
    }

    .content-area {
        padding-bottom: 4rem;
        /* Add space for footer */
    }
}

/* Regular mobile portrait styles */
@media (max-width: 768px) {

    html,
    body {
        min-height: 100vh;
    }

    .minimal-home {
        padding: 1rem 1rem 0;
        min-height: 100vh;
        overflow: auto;
    }

    /* ... rest of your mobile styles ... */
}