/* BlankHeadsMintPage.css */
.blank-heads-mint-page {
    padding: 20px;
    background-color: #ffffff;
}

.nft-landing {
    text-align: center;
    margin-top: 40px;
}

.nft-landing h1 {
    font-size: 5em;
    font-weight: 400;
}

.nft-image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.nft-image-placeholder img {
    max-width: 600px;
    width: 100%;
    height: auto;
    /* Maintains aspect ratio */
}

.nft-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.nft-progress-bar {
    width: 80%;
    height: 10px;
    background-color: #000;
    border-radius: 5px;
    position: relative;
}

.nft-progress span {
    margin-left: 10px;
    font-size: 1em;
}

.nft-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.nft-connect-wallet,
.nft-mint {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
}

.nft-info {
    text-align: center;
    margin: 0 auto;
    width: 80%;
}

.nft-info p {
    margin-bottom: 10px;
}