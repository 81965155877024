/* Featured.css */
.featured {
    background-color: #f9f9f9;
    background-image: url('../public/feature-lit.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    height: 100vh;
    padding: 3em;
    position: relative;
}

.featured h1 {
    color: white;
    font-size: 2rem;
}

.featured__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.featured__details {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    color: #fff;
}

.featured__artist {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
}

.featured__artist-icon {
    width: 40px;
    aspect-ratio: 1 / 1;
    background-color: #d3d3d3;
    border-radius: 50%;
    margin-right: 0.5em;
}

.featured__artist-name {
    color: #fff;
    text-decoration: none;
    font-size: 5rem;
    width: 100%;
    padding: 0;
    margin: 0;
}

.spotlight {
    background-color: #efefef;
    padding: 2em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
    margin-top: -50px;
    /* To create overlap */
    margin-left: auto;
    margin-right: auto;
    /* Near full-screen height */
    display: flex;
    flex-direction: column;
    border-radius: 50px;
}

.spotlight__title {
    font-size: 5rem;
    margin-bottom: 1em;
    /* text-align: center; */
    width: 50%;
}

.spotlight__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.spotlight__text {
    width: 48%;
    margin-bottom: 92px;
}

.spotlight__extra {
    width: 48%;
    display: flex;
    justify-content: center;
}

/* Gallery grid styles */
.spotlight__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    width: 100%;
}

.spotlight__image-wrapper {
    position: relative;
    width: 100%;
    /* Remove the padding-top */
    overflow: hidden;
}

.spotlight__image {
    width: 100%;
    height: auto; /* Change from 100% to auto */
    object-fit: contain; /* Change from cover to contain */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .spotlight__content {
        flex-direction: column;
    }

    .spotlight__text,
    .spotlight__extra {
        width: 100%;
    }

    .spotlight__grid {
        grid-template-columns: 1fr;
        /* 1 image per row on smaller screens */
    }
}