.App {
  overflow-x: hidden;
}


/* App.css */
body {
  font-family: 'Tomorrow', sans-serif;
  overflow-x: hidden;

}

h1,
h2 {
  font-weight: 300;
}

/* Ensure all other font declarations use 'Tomorrow' as well */
.hero__title,
.hero__subheading,
.hero__description,
.hero__cta,
.fullscreen-menu__item {
  font-family: 'Tomorrow', sans-serif;
}

button {
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Scrollbar Styling */
body::-webkit-scrollbar {
  width: 8px;
  /* Adjust width for a thin scrollbar */
  overflow-x: hidden;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Rounded corners for the scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Scrollbar track color */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}