/* AboutPopup.css */
.about-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.about-popup {
    background-color: #fff;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
    position: relative;
}

.about-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2em;
    color: #004177;
    cursor: pointer;
}

.about-popup h2 {
    margin-top: 0;
    margin-bottom: 1em;
}

.about-popup p {
    margin: 0;
    line-height: 1.5;
}