.footer {
    background-color: #f8f8f8;
    padding: 1em 0;
    text-align: center;
    font-size: 0.9em;
    color: #333;
    border-top: 1px solid #e0e0e0;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer p {
    margin: 0;
}