/* Header.css */
.header {
    background-color: #fff;
    position: relative;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    color: #004177;
}

a {
    color: #004177;

}

.nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    z-index: 999;
}

.nav__logo {
    height: 50px;
    margin-left: 33px;
    /* filter: brightness(0) invert(1);  */
}

.nav__toggle {
    cursor: pointer;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding-right: 33px;
    /* color: white; Makes hamburger icon white */
}

/* Fullscreen Menu Styles */
.fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.fullscreen-menu__items {
    list-style: none;
    text-align: center;
}

.fullscreen-menu__item {
    font-size: 2.5em;
    margin: 0.5em 0;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    color: #004177;

    /* Added for popup positioning */
}

.fullscreen-menu__item:hover {
    color: #000;
}

.fullscreen-menu__item:first-of-type {
    text-decoration: underline;
}

.fullscreen-menu__item--disabled {
    cursor: not-allowed;
    /* Change cursor to not-allowed */
}

.fullscreen-menu__item--disabled:hover::after {
    content: "Coming soon!";
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.5em;
    white-space: nowrap;
    z-index: 9999;
}

/* Responsive styles */
@media (max-width: 768px) {
    .nav__toggle {
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
    }

    .nav__logo {
        height: 40px;
    }
}