/* Hero.css */
.hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85vh;
    padding: 0em 5em;
    position: relative;
    /* Add relative positioning to parent container */
}

.hero__content {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 300px;
    padding: 0 40px 40px 40px;
}

.hero__title {
    font-size: 3em;
    font-weight: 300;
    /* White background with low opacity */
    /* Add padding to create spacing around the text */
    border-radius: 5px;
    /* Optional: Add border radius for rounded corners */
}

.hero__info {
    position: absolute;
    /* Position it absolutely within the hero section */
    bottom: 20px;
    /* Distance from the bottom */
    right: 20px;
    /* Distance from the right */
    text-align: right;
    /* Align text to the right */
}

.hero__subheading {
    font-size: 0.9em;
    margin-top: 0.5em;
}

.hero__description {
    margin-top: 1em;
    line-height: 0;
    font-size: 0.8em;
}

.hero__right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero__cta {
    padding: 0.5em 2em;
    /* padding-right: 0; */
    color: #004177;
    background-color: rgba(255, 255, 255, 0.5);

    border: 0.5px solid #004177;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    /* align-items: center; */
    font-size: 1.2em;
    transition: all 0.3s ease;
    /* border-radius: 50px; */
    margin-top: 40px;
}

.hero__cta:hover {
    transform: translateX(10px);

}

.hero__cta-arrow {
    margin-left: 0.5em;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        height: 50vh;
        padding: 2em 1em;
        align-content: center;
    }



    .hero__title {
        z-index: 999;
        margin: 40px 0px;
    }

    .hero__content {
        /* padding: 0; */
        border: 1px solid #004177;
    }

    .hero__info {
        position: static;
        /* Reset position on smaller screens */
        text-align: center;
        /* Center text on smaller screens */
        margin-top: 1em;
        bottom: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 4px;
    }

    .hero__left {
        text-align: center;
        max-width: 100%;
    }

    .hero__right {
        margin-top: 2em;
    }

    .hero__cta {
        margin: 0 auto;
        transform: translateY(33px);

        /* position: absolute; */
        /* top: 50%; */
        /* transform: translateY(75px); */
        /* transform: translateX(50%); */
    }

    .hero__cta:hover {
        transform: translateX(20px);
        transform: translateY(33px);

    }
}